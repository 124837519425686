<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong></strong> Profile
      </h3>
    </header>
    <div class="container">

    <form @submit.prevent="changePassword">
      <h4>Change Password</h4>
      <div class="form-group">
        <label for="currentPassword">Current Password</label>
        <input v-model="currentPassword" type="password" class="form-control" id="currentPassword" required>
      </div>
      <div class="form-group">
        <label for="newPassword">New Password</label>
        <input v-model="newPassword" type="password" class="form-control" id="newPassword" required>
      </div>
      <div class="form-group">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input v-model="confirmNewPassword" type="password" class="form-control" id="confirmNewPassword" required>
      </div>
      <button type="submit" class="btn btn-primary">Change Password</button>
    </form>
  </div>

  </div>
</template>

<script>
import gql from "graphql-tag";
import { getTokenInfo } from "../services/auth.check"
import { isAuthenticated } from '../services/auth.check';
import {apolloContext} from '../apollo.provider'
import {useToast } from "vue-toastification";


const GET_MAIL =  gql`
{
  getMail {
    message
    subject
    email
    readflag
    __typename
  }
}
    `

    
const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($username: String!, $password: String!, $newPassword: String!) {
    changePassword(username: $username, password: $password, newPassword: $newPassword)
  }
`;
    

export default {
  name: 'Profile',
  data() {
    return {
      getTokenInfo: getTokenInfo(),
      isAuthenticated: isAuthenticated(),
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
          }},
  computed: {
    currentUser() {

        console.log("token info: " + JSON.stringify(this.getTokenInfo))
      
      return null
    }
  },
   apollo: {
    getMail: {
      query: GET_MAIL
    }
    },
    methods: {
  async changePassword() {

    let user = getTokenInfo().sub
    try {
      // Perform client-side validation, e.g., checking if new passwords match
      if (this.newPassword !== this.confirmNewPassword) {
          this.toast.error("Passwords does not match!");
        return;
      }
      apolloContext.requiresAuth = true;
      // Send a mutation request to change the password
      const response = await this.$apollo.mutate({
        
        mutation: CHANGE_PASSWORD_MUTATION,
        variables: {
          username: user,
          password: this.currentPassword,
          newPassword: this.newPassword
        }
      });

      // Handle the response from the backend
      if (response.data.changePassword) {
        this.toast.success("Password has been changed");
      } else {
        this.toast.error("Oops, something happened! Try again");
      }
    } catch (error) {
      this.toast.error("Oops, something happened! Try again");
    }
  }
},
setup() {
      const toast = useToast();
      return { toast  }
    },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push('/login');
    }
  }
};


</script>
